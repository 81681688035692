import { isArray } from "lodash";
import React, { useState, useEffect } from "react";
import useQuery from "../Hooks/Query";
import ThemeLoader from "./ThemeLoader";

const UserSelect = ({ id, selected, multiple, onChange, disabled, department, cssClass = "form-control" }) => {
	const [activePage, setActivePage] = useState(1);
	const [pageSize, setPageSize] = useState(50);
	const [totalItems, setTotalItems] = useState(null);
	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [searchCriteria, setSearchCriteria] = useState("");

	const [items, setItems] = useState([]);
	const [error, setError] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [helper, setHelper] = useState(false);
	useQuery(setHelper);

	// Temporary for NS - no users table to filter marketing department so need to use static list
	const nsMarketingEmails = ["mbisping@northstarmls.com", "mboldt@northstarmls.com", "acosta@northstarmls.com", "klattner@northstarmls.com", "dlewis@northstarmls.com", "doestreich@northstarmls.com", "dzimmerman@northstarmls.com"]

	useEffect(() => {
		if (helper) {
			fetchData();
		}
	}, [id, activePage, searchCriteria, helper]);

	const fetchData = () => {
		if (helper) {
			setIsLoaded(false);

			// set the vars for the query helper
			helper.pageId = activePage - 1; // subtract one since the framework uses zero index
			helper.pageSize = pageSize;
			helper.orderBy = createOrderObject();
			helper.fetchAllData("members", [{
				field: "MemberPrimaryEmail",
				op: "Equal",
				values: nsMarketingEmails
			}]).then(
				(result) => {
					let results = result;
					// Sort list by last name ascending
					results = results.sort((a, b) => (a.memberLastName.split(" ").pop()[0] > b.memberLastName.split(" ").pop()[0] ? 1 : -1));
					setTotalItems(results.length);
					setItems(results);
					setIsLoaded(true);
				},
				(error) => {
					setIsLoaded(true);
					setError(error);
				}
			);
		}
	};

	const createOrderObject = () => {
		if (!sortField || !sortOrder) {
			return [];
		}

		return [
			{
				field: sortField,
				direction: sortOrder,
			},
		];
	};

	const buildUsers = () => {
		return items.map((user) => (
			<option key={user.id} value={user.id}>
				{user.memberFirstName} {user.memberLastName}
			</option>
		));
	};

	if (error) {
		return <div>Error: {error.message ?? "Could not retrieve results"}</div>;
	} else if (!isLoaded) {
		return <ThemeLoader />;
	} else {
		return (
			<>
				<select id={id} value={selected} onChange={onChange} multiple={multiple} disabled={disabled} className={cssClass}>
					<option value="">Select User</option>
					{buildUsers()}
				</select>
			</>
		);
	}
};

export default UserSelect;
