/*
 * NOTE: use fontawesome 5 free icons or icomoon icons but use full icon class, with fas etc.
 * FA 5: https://fontawesome.com/icons?d=gallery&q=buildin&m=free
 * IcoMoon: https://iconsmind.com/view_icons/
 */

const Routes = [
	{
		name: "Communications",
		abbrev: "CO",
		icon: "fas fa-bullhorn",
		rootpath: "/communications",
		paths: [
			{
				path: "/communications",
				name: "Dashboard",
				icon: "icon-Gaugage",
				showWelcome: true,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
				],
			},
			{
				path: "/communications/articles",
				name: "Articles",
				icon: "fas fa-file",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Articles", path: "/communications/articles" },
				],
			},
			{
				path: "/communications/audiences",
				name: "Audiences",
				icon: "fas fa-users",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Audiences", path: "/communications/audiences" },
				],
			},
			{
				path: "/communications/campaigns",
				name: "Campaigns",
				icon: "fas fa-envelope",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Campaigns", path: "/communications/campaigns" },
				],
			},
			{
				path: "/communications/media",
				name: "Media Manager",
				icon: "fas fa-photo-video",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
				],
			},
			{
				path: "/communications/reports",
				name: "Reports",
				icon: "fas fa-chart-bar",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Reports", path: "/communications/reports" },
				],
			},
			{
				path: "/communications/templates",
				name: "Templates",
				icon: "fas fa-file",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Templates", path: "/communications/templates" },
				],
			},
		],
	},
	{
		name: "Operations",
		icon: "fas fa-building",
		abbrev: "OP",
		rootpath: "/operations",
		paths: [
			{
				path: "/operations/audience-applications",
				name: "Applications",
				icon: "fas fa-users",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/custom-canvases",
				name: "Custom Canvases",
				icon: "fas fa-table",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/email-validator",
				name: "Email Validator",
				icon: "fas fa-envelope",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/eula",
				name: "EULA Manager",
				icon: "fas fa-file-signature",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/media",
				name: "Media Manager",
				icon: "fas fa-photo-video",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/system-alerts",
				name: "System Messages",
				icon: "fas fa-bell",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
		],
	},
];

export default Routes;
