import React from "react";
import User from "./Widgets/User";

const Header = () => {
	// NOTE: This is required for LITE version. Work around for metis menu - does not handle jquery class toggle.. oye
	const handleMenuCollapse = (e: any) => {
		const sideBarElement = document.getElementById("page-aside");
		const sideBarBtn = document.getElementsByClassName("sidenav-btn");
		if (sideBarElement && sideBarBtn) {
			sideBarElement.classList.toggle("aside-folded");
			for (let i = 0; i < sideBarBtn.length; i++) {
				sideBarBtn[i].classList.toggle("toggler-folded");
			}
		}
	};

	return (
		<header className="navbar page-header whiteHeader navbar-expand-lg">
			<ul className="nav flex-row mr-auto">
				<li className="nav-item">
					<a href="#" className="nav-link sidenav-btn h-lg-down" onClick={(e) => handleMenuCollapse(e)}>
						<span className="navbar-toggler-icon" />
					</a>
					<a className="nav-link sidenav-btn h-lg-up" href="#page-aside" data-toggle="dropdown" data-target="#page-aside">
						<span className="navbar-toggler-icon" />
					</a>
				</li>
			</ul>
			<ul className="nav flex-row order-lg-2 ml-auto nav-icons">
				<li className="nav-item dropdown user-dropdown align-items-center">
					<User />
				</li>
			</ul>
		</header>
	);
};

export default Header;
